import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import DvrIcon from '@mui/icons-material/Dvr';
import Checkbox from '@mui/material/Checkbox';


import secureApiFetch from '../../../services/secureFetchApi';
import TableLoader from '../../../styles/TableLoader';
import DateFormat from '../../../ui/DateFormat';
import Folder from '../../../ui/Folder'
import '../../../styles/table.css'
import NavigateBreadCrumbs from '../../../ui/NavigateBreadCrumbs';
import CopyText from '../../../feature/CopyText';
import ColorBadge from '../../../ui/ColorBadge';
import ScanFilter from './ScanFilter';

const ScanDetails = () => {
    const [loading, setLoading] = useState(false)
    const [checked, setChecked] = useState({});
    const [scanType, setScanType] = useState([])
    const [details, setDetails] = useState({})

    const [selectJobId, setSelectedJobId] = useState([])
    const { id } = useParams()

    const handleChange = (jobId) => (event) => {
        setChecked((prevChecked) => ({
            ...prevChecked,
            [jobId]: event.target.checked,
        }));

        setSelectedJobId((prevSelected) =>
            event.target.checked
                ? [...prevSelected, jobId]
                : prevSelected.filter((id) => id !== jobId)
        );
    };

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        const allJobIds = Object.keys(details).flatMap(domain =>
            details[domain]
                .filter(item => item.status !== 'inprogress')
                .map(item => item.job_id)
        );
        setChecked(
            allJobIds.reduce((acc, jobId) => ({
                ...acc,
                [jobId]: isChecked,
            }), {})
        );
        setSelectedJobId(isChecked ? allJobIds : []);
    };

    const getDetails = () => {
        setLoading(true);
        secureApiFetch(`/api/v1/result/scan_code/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Access': 'application/json'
            }
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                const jobIdsArray = extractJobIds(data);
                const scanTypeSet = new Set();
                for (const projectName in data) {
                    if (data.hasOwnProperty(projectName)) {
                        const projectItems = data[projectName];
                        projectItems.forEach(item => {
                            if (item.scan_type) {
                                scanTypeSet.add(item.scan_type);
                            }
                        });
                    }
                }
                setScanType([...scanTypeSet]);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const extractJobIds = (data) => {
        let jobIdsArray = [];
        for (let key in data) {
            if (Array.isArray(data[key])) {
                data[key].forEach(item => {
                    if (item.job_id) {
                        if (item.status !== 'inprogress') {
                            jobIdsArray.push(item.job_id);
                        }
                    }
                });
            }
        }

        return jobIdsArray;
    };

    const handleFetchSelectedData = () => {
        const url = new URL('/result/ResultData', window.location.origin);
        // url.searchParams.append('jobs', JSON.stringify(selectJobId));
        window.open(url, '_blank');
        localStorage.setItem('jobIds', selectJobId)
    }

    useEffect(() => {
        if (id) {
            getDetails()
        }
    }, [id])

    return (
        <div className='bg-white shadow rounded'>
            <div className='flex justify-between items-center px-3 pb-3'>
                <div className='py-1'>
                    <NavigateBreadCrumbs>
                        <span>Back</span>
                        <Link to="/schedulescan">Schedule Scan</Link>
                        <span>scan details</span>
                    </NavigateBreadCrumbs>
                    <Folder label='Scan Id Details' icon={<DvrIcon color='#3D3A89' />} />
                </div>
                <div className="flex space-x-2">
                    <Button variant='contained' color='customColor' onClick={handleFetchSelectedData} disabled={selectJobId.length === 0} >
                        Get Scan Results
                    </Button>
                </div>
            </div>
            <div className='px-2'>
                <ScanFilter
                    scanId={id}
                    scanType={scanType}
                    setDetails={setDetails}
                    setLoading={setLoading}
                />
                <hr className='mb-2' />
                <div className='min-h-[75vh]'>
                    <table className="table-container">
                        <thead className="table-heading text-left ">
                            <tr>
                                <th className="th text-right">
                                    Index
                                    <Checkbox
                                        checked={Object.keys(details).flatMap(domain =>
                                            details[domain]
                                                .filter(item => item.status !== 'inprogress')
                                                .map(item => item.job_id)
                                        ).every(jobId => checked[jobId])}
                                        onChange={handleSelectAll}
                                        inputProps={{ 'aria-label': 'select all' }}
                                    />
                                </th>
                                <th className="th">Id</th>
                                <th className="th">Job Id</th>
                                <th className="th">Scan Type</th>
                                <th className="th">Status</th>
                                <th className="th">Last Modified</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {loading && (<tr>
                                <td colSpan={11}><TableLoader /></td>
                            </tr>)}
                            {!loading && Object.keys(details).length === 0 && (
                                <tr>
                                    <td colSpan={8} align="center " className='py-3 font-bold text-xl text-red-600'>
                                        Nothing to see here... yet.
                                    </td>
                                </tr>
                            )}
                            {!loading && Object.keys(details).length > 0 && Object.keys(details).length !== 0 && Object.keys(details).map((domain) => (
                                <React.Fragment key={domain}>

                                    {
                                        details[domain].map((item, index) => (
                                            <tr key={index} className="data-row text-left">
                                                <td className="td font-semibold text-right">
                                                    {index + 1}
                                                    <Checkbox
                                                        checked={!!checked[item.job_id]}
                                                        onChange={handleChange(item.job_id)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                        disabled={item.status === 'inprogress'}
                                                    />
                                                </td>
                                                <td className="td font-semibold"> {item.id} </td>
                                                <td className="td font-semibold">
                                                    <Link to={`/result/job/${item.job_id}`}> {item.job_id} </Link>
                                                    <CopyText text={item.job_id} message={'Job Id copied!'} />
                                                </td>
                                                <td className="td font-semibold"> {item.scan_type} </td>
                                                <td className="td font-semibold">
                                                    <ColorBadge badge={item.status} />
                                                </td>
                                                <td className="td font-semibold"> <DateFormat date={item.update_ts} /> </td>
                                            </tr>
                                        ))
                                    }
                                </React.Fragment>
                            ))}

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    )
}

export default ScanDetails