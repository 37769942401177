import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';

import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import secureApiFetch from '../../services/secureFetchApi';
import PaginationTable from '../../feature/PaginationTable';
import Folder from '../../ui/Folder';
import ScheduleTable from './table';

const ScheduleScan = () => {
  const [clientId, setClientId] = useState('')
  const [sheduleTable, setScheduleTable] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false)

  const handelChange = (e) => {
    const id = e.target.value
    setClientId(id)
  }

  const getSchedule = (page) => {
    setLoading(true);
    secureApiFetch(`/api/v1/schedule_scan?page=${page}&descending=true`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((res) => {
        const totalPagesCount = parseInt(res.headers.get('X-Page-Count'));
        setTotalPages(totalPagesCount);
        return res.json();
      })
      .then((data) => {
        setScheduleTable(data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error('Failed to retrieve data for this ID.');
        console.error('Client ID Not Found:', error);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    secureApiFetch(`/api/v1/schedule_scan/client_id/${clientId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then((res) => {
        const totalPagesCount = parseInt(res.headers.get('X-Page-Count'));
        setTotalPages(totalPagesCount);
        if (!res.ok) {
          setScheduleTable([])
        }
        return res.json();
      })
      .then((data) => {
        setScheduleTable(data);
      })
      .catch((error) => {
        toast.error('Failed to retrieve data for this ID.');
        console.error('Client ID Not Found:', error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    getSchedule(currentPage)
  }, [currentPage, totalPages])

  return (
    <div className="bg-white shadow rounded">
      <div className='flex justify-between items-center py-1 px-3'>
        <div>
          <NavigateBreadCrumbs>
            <span>Back</span>
            <span>Schedule Scan</span>
          </NavigateBreadCrumbs>
          <Folder label='Schedule Scan' icon={<PendingActionsOutlinedIcon color='3A3D89' />} />
        </div>

        <PaginationTable totalPages={totalPages} page={currentPage} changePage={handleChangePage} />

        <div className="flex space-x-3 items-center">
          <Link to='/schedulescan/create'>
            <Button variant="contained" color='customColor' >Add Scan</Button>
          </Link>
        </div>
      </div>
      <hr />
      <div className='flex justify-end my-1 px-3'>
        <Paper component="form" sx={{ p: '0px 2px', display: 'flex', alignItems: 'center', width: 220 }}>
          <InputBase sx={{ ml: 1, flex: 1, fontSize: '12px' }} onChange={handelChange} placeholder="Search Table by Client Id" inputProps={{ 'aria-label': 'search table by Client Id' }} />
          <Divider sx={{ height: 24, m: 0.5 }} orientation="vertical" />
          <IconButton onClick={handleSubmit} type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon fontSize='small' />
          </IconButton>
        </Paper>
      </div>

      <div className='min-h-[82vh] px-2'>
        <ScheduleTable getSchedule={getSchedule} sheduleTable={sheduleTable} loading={loading} />
      </div>
    </div>
  )
}

export default ScheduleScan;