import React from 'react';

const Renders3scanner = ({ result }) => {
    console.log('result',result)
    const printObject = (obj) => {
        return Object.keys(obj).map((key, index) => (
            <li key={index}>
                <strong className="text-[#3A3D89]">{key}:</strong> {obj[key] !== null && obj[key] !== undefined ? obj[key].toString() : "N/A"}
            </li>
        ));
    };

    return (
        <div className="p-4 bg-gray-50">
            <ul className="list-disc list-inside space-y-3 text-gray-800">
                {printObject(result.bucket)}
                {printObject({ level: result.level, time: result.time, msg: result.msg })}
            </ul>
        </div>
    );
};

export default Renders3scanner;
