import React from 'react'

const RenderOpenai = ({ result }) => {
    return (
        <div className="p-4 bg-gray-50">
            <ul className="list-disc list-inside space-y-3 text-gray-800">
                <li><strong className="text-[#3A3D89]">Name:</strong> {result.name || "Name not provided"}</li>
                <li><strong className="text-[#3A3D89]">Website:</strong> <a href={`https://${result.website}`} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.website || "Website not provided"}</a></li>
                <li><strong className="text-[#3A3D89]">Industry:</strong> {result.industry || "Industry information not provided"}</li>
                <li><strong className="text-[#3A3D89]">Description:</strong> {result.description || "Description not provided"}</li>
                <li><strong className="text-[#3A3D89]">Founded Year:</strong> {result.company_founded_year || "Year not provided"}</li>
                <li><strong className="text-[#3A3D89]">Contact Email:</strong> {result.contact_email || "Email not provided"}</li>
                <li><strong className="text-[#3A3D89]">Address:</strong> {result.address || "Address not provided"}</li>
                <li><strong className="text-[#3A3D89]">City:</strong> {result.city || "City not provided"}</li>
                <li><strong className="text-[#3A3D89]">State:</strong> {result.state || "State not provided"}</li>
                <li><strong className="text-[#3A3D89]">Country:</strong> {result.country || "Country not provided"}</li>
                <li><strong className="text-[#3A3D89]">Postal Code:</strong> {result.postal_code || "Postal code not provided"}</li>
                <li><strong className="text-[#3A3D89]">LinkedIn:</strong> {result.linkedin_url ? <a href={result.linkedin_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.linkedin_url}</a> : "LinkedIn URL not provided"}</li>
                <li><strong className="text-[#3A3D89]">Twitter:</strong> {result.twitter_url ? <a href={result.twitter_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.twitter_url}</a> : "Twitter URL not provided"}</li>
                <li><strong className="text-[#3A3D89]">Facebook:</strong> {result.facebook_url ? <a href={result.facebook_url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">{result.facebook_url}</a> : "Facebook URL not provided"}</li>
            </ul>
        </div>
    )
}

export default RenderOpenai