import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CropFreeOutlinedIcon from '@mui/icons-material/CropFreeOutlined';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import PersonIcon from '@mui/icons-material/Person';
import Collapse from '@mui/material/Collapse';

const SidebarDrawer = ({ isSidebarOpen }) => {
    const location = useLocation();
    const isActive = (path) => location.pathname === path;
    return (
        <div className={`min-h-screen text-xs w-72 bg-white mt-4 mr-4 ml-2 rounded-md shadow ${isSidebarOpen ? '' : 'hidden'}`}>
            <ul className="mt-4">
                <NavItem to="/" icon={<DashboardIcon />} text="Dashboard" isActive={isActive} />
                <NavItem to="/schedulescan" icon={<AccessTimeFilledIcon />} text="Schedule Scan" isActive={isActive} />
                <NavItem to="/services" icon={<RssFeedIcon />} text="Services" isActive={isActive} />
                <NavItem to="/runscan" icon={<CropFreeOutlinedIcon />} text="Run Quick Scan" isActive={isActive} />
                <NavItemWithSubmenu to="/queuestatus" icon={<PeopleAltIcon />} text="Status & Result" isActive={isActive}>
                    <SubNavItem to="/queuestatus" text="Queue Status" isActive={isActive} />
                    <SubNavItem to="/result" text="Result" isActive={isActive} />
                </NavItemWithSubmenu>
                <NavItem to="/setting" icon={<SettingsIcon />} text="Setting" isActive={isActive} />
                <NavItem to="/users" icon={<PersonIcon />} text="Users" isActive={isActive} />
            </ul>
        </div>
    );
}

const NavItem = ({ to, icon, text, isActive }) => (
    <li className="my-3 mx-2">
        <Link
            to={to}
            className={`flex items-center rounded-lg px-3 py-2 hover:text-[#EB1F36]  ${isActive(to)
                ? ' font-semibold text-[#EB1F36]'
                : 'text-gray-800'
                }`}
        >
            <span className="mr-2">{icon}</span> {text}
        </Link>
    </li>
);

const NavItemWithSubmenu = ({ icon, text, children, isActive, to }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <li className="my-3 mx-2">
            <Link
                to={to}
                onClick={handleToggle}
                className={`flex items-center justify-between rounded-lg px-3 py-2 hover:text-[#EB1F36]  ${!isActive ? ' font-semibold text-[#EB1F36]' : 'text-gray-800' }`}
            >
                <div><span className="">{icon}</span> {text}</div>
                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </Link>
            <Collapse in={isOpen}>
                <ul className="ml-6">{children}</ul>
            </Collapse>
        </li>
    );
};

const SubNavItem = ({ to, text, isActive }) => (
    <li>
        <Link
            to={to}
            className={`flex items-center rounded-lg px-3 py-2 hover:text-[#EB1F36] ${isActive(to)
                ? ' font-semibold text-[#EB1F36]'
                : 'text-gray-800'
                }`}
        >
            {text}
        </Link>
    </li>
);

export default SidebarDrawer;
