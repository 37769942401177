import React from 'react'

const RenderWhoisData = ({result}) => {
    return (
        <div className="bg-[#F9F9F9]">
            <p className="text-md"><span className="font-semibold text-[#3A3D89]">Query:</span> {result.query}</p>
            <p className="text-md"><span className="font-semibold text-[#3A3D89]">Website:</span> {result.website}</p>
            <p className="text-md"><span className="font-semibold text-[#3A3D89]">Status:</span> {result.status}</p>

            <div className="mt-4">
                <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Popularity:</h4>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Rank:</span> {result.popularity.rank}</p>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Visitors:</span> {result.popularity.visitors}</p>
                <img src={result.popularity.image} alt="Popularity" className="my-2 max-w-xs" />
                <p className="text-md">{result.popularity.text}</p>
            </div>

            <p className="text-md mt-4"><span className="font-semibold text-[#3A3D89]">IP Address:</span> {result.ip_address}</p>
            <p className="text-md"><span className="font-semibold text-[#3A3D89]">IPv6 Address:</span> {result.ipv6_address}</p>

            <div className="mt-4">
                <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Location:</h4>
                <p className="text-md">
                    <span className="font-semibold text-[#3A3D89]">Country:</span> {result.location.countryName}
                    <img src={result.location.countryIcon} alt={result.location.countryName} className="inline-block ml-2 h-4 w-4 align-middle" />
                </p>
            </div>

            <div className="mt-4">
                <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Reverse DNS:</h4>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Host:</span> {result.reverse_dns.host}</p>
            </div>

            <div className="mt-4">
                <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">Owners:</h4>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Owner Name:</span> {result.owners.owner.ownerName}</p>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Provider:</span> {result.owners.owner.provider}</p>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Address:</span> {result.owners.owner.address}</p>
                <p className="text-md">
                    <span className="font-semibold text-[#3A3D89]">Country:</span> {result.owners.owner.countryName}
                    <img src={result.owners.owner.countryIcon} alt={result.owners.owner.countryName} className="inline-block ml-2 h-4 w-4 align-middle" />
                </p>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Website:</span> {result.owners.owner.website}</p>
                <p className="text-md"><span className="font-semibold text-[#3A3D89]">Phone:</span> {result.owners.owner.phone}</p>
                <a href={result.owners.owner.link} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">More details</a>
            </div>

            <div className="mt-4">
                <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">DNS Records:</h4>
                {result.dns.map((dnsRecord, index) => (
                    <div key={index} className="mb-2">
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">Nameserver:</span> {dnsRecord.nameserver}</p>
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">IP Address:</span> {dnsRecord.ip_address}</p>
                        <p className="text-md">
                            <span className="font-semibold text-[#3A3D89]">Country:</span> {dnsRecord.countryName}
                            <img src={dnsRecord.countryIcon} alt={dnsRecord.countryName} className="inline-block ml-2 h-4 w-4 align-middle" />
                        </p>
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">Sites:</span> {dnsRecord.sites}</p>
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">Top Sites:</span> {dnsRecord.topSites}</p>
                    </div>
                ))}
            </div>

            <div className="mt-4">
                <h4 className="text-lg font-semibold text-[#3A3D89] mb-2">IP Change History:</h4>
                {result.ip_change_history.map((history, index) => (
                    <div key={index} className="mb-2">
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">IP Address:</span> {history.ip_address}</p>
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">Host:</span> {history.host}</p>
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">Date When Website Was Using IP:</span> {history.date_when_website_was_using_ip}</p>
                        <p className="text-md"><span className="font-semibold text-[#3A3D89]">Date When Found That Website Changed IP:</span> {history.date_when_found_that_website_changed_ip}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default RenderWhoisData