import React from 'react';

const RenderWebvulnFinder = ({ result }) => {
    const truncateText = (text = '') => {
        return text.length > 200 ? text.slice(0, 200) + '...' : text;
    };

    const truncateChunks = (chunks = []) => {
        const combinedText = chunks.join(' '); // Combine chunks into a single string
        return truncateText(combinedText); // Truncate the combined string
    };

    if (typeof result === 'object' && result !== null) {
        const requestChunks = result.request;
        const responseChunks = result.response;

        return (
            <div className="mb-4 p-2">
                <h2 className="text-lg font-bold mb-2">{result.name}</h2>
                <p className="text-sm text-gray-600 mb-2">Severity: {result.severity}</p>
                <p className="mb-2">{result.description}</p>

                <div className="mb-2">
                    <strong>Request:</strong>
                    <pre className="bg-gray-100 p-1 mt-1 rounded">
                        {requestChunks ? (
                            Array.isArray(requestChunks) ? (
                                <span>{truncateChunks(requestChunks)}</span>
                            ) : (
                                <span>{truncateText(requestChunks)}</span>
                            )
                        ) : (
                            <span>No request data available</span>
                        )}
                    </pre>
                </div>

                <div>
                    <strong>Response:</strong>
                    <pre className="bg-gray-100 p-1 mt-1 rounded">
                        {responseChunks ? (
                            Array.isArray(responseChunks) ? (
                                <span>{truncateChunks(responseChunks)}</span>
                            ) : (
                                <span>{truncateText(responseChunks)}</span>
                            )
                        ) : (
                            <span>No response data available</span>
                        )}
                    </pre>
                </div>
            </div>
        );
    }

    return null;
};

export default RenderWebvulnFinder;
