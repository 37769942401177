import React from 'react'

const S3scannerjobDetail = ({result}) => {
    const printObject = (obj) => {
        return Object.keys(obj).map((key, index) => (
            <li key={index}>
                <strong className="text-[#3A3D89]">{key}:</strong> {obj[key] !== null && obj[key] !== undefined ? obj[key].toString() : "N/A"}
            </li>
        ));
    };

    return (
        <div className="p-4">
            {result.map((item, index) => (
                <div key={index} className="mb-6">
                    <h2 className="font-bold text-lg text-[#3A3D89] mb-3">Bucket #{index + 1}</h2>
                    <ul className="list-disc list-inside space-y-1 text-gray-800">
                        {printObject(item.bucket)}
                        <li>
                            <strong className="text-[#3A3D89]">level:</strong> {item.level}
                        </li>
                        <li>
                            <strong className="text-[#3A3D89]">time:</strong> {item.time}
                        </li>
                        <li>
                            <strong className="text-[#3A3D89]">msg:</strong> {item.msg || "N/A"}
                        </li>
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default S3scannerjobDetail