import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DotLoader from '../../styles/DotLoader';
import CopyText from '../../feature/CopyText';
import ColorBadge from '../../ui/ColorBadge';
import DateFormat from '../../ui/DateFormat';

const DetailsScan = ({ scheduleDetals, loading }) => {
    const subTable = scheduleDetals.main_scans_details;
    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead className='bg-[#3A3D89]'>
                        <TableRow>
                            <TableCell sx={{ color: 'white', fontWeight: '600' }}>Id</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: '600' }} align="left">Scan Code</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: '600' }} align="left">Status</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: '600' }} align="left">Schedule</TableCell>
                            <TableCell sx={{ color: 'white', fontWeight: '600' }} align="left">View Result</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading && (
                            <TableRow>
                                <TableCell colSpan={5} sx={{ padding: '1.5rem 0rem' }}>
                                    <DotLoader />
                                </TableCell>
                            </TableRow>
                        )}
                        {!loading && subTable.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={5} align="center" className='py-3 font-bold text-xl text-red-600'>
                                    Nothing to see here... yet.
                                </TableCell>
                            </TableRow>
                        )}
                        {!loading && subTable.length > 0 && subTable.map((item) => (
                            <TableRow key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left" className='font-bold'>{item.id}</TableCell>
                                <TableCell align="left" sx={{ fontWeight: '600' }}>
                                    {item.scan_code}
                                    <CopyText text={item.scan_code} message={'Scan code copied!'} />
                                </TableCell>
                                <TableCell align="left">
                                    <ColorBadge badge={item.status} />
                                </TableCell>
                                <TableCell align="left"><DateFormat date={item.schedule} /></TableCell>
                                <TableCell align="left">
                                    <Link to={`/result/scan/${item.scan_code}`} >
                                        <Button variant='contained' color='customColor'>
                                            Next
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default DetailsScan;
