import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Index from "./component/AppBar/Index";
import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/theme';
import DashboardRoutes from './component/dashboard/route'
import Login from './component/login/Login';
import SettingsRoutes from './component/setting/route';
import PageNotFound from './component/login/PageNotFound';
import ServicesRoute from './component/service list/route';
import ScheduleScanRoute from './component/schedule scan/route'
import RunQuickScanRoute from './component/run quick scan/route';
import ResultRoute from './component/status result/result/route';
import StatusRoute from './component/status result/queue status/route';
import SimpleLayout from './component/AppBar/Simplelayout';
import ResultData from './component/status result/result/report/ResultData';
import UserRoute from './component/user/route';
import Register from './component/login/Register';
import { ProtectedRoute } from './component/login/ProtectedRoute';

function App() {

  // useEffect(() => {
  //   document.body.style.zoom = "80%";
  // }, []);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path={`/registerUser`} element={<Register />} />
        <Route path="/" element={<ProtectedRoute Component={Index} />} >
          {
            [
              ...DashboardRoutes,
              ...SettingsRoutes,
              ...ServicesRoute,
              ...ScheduleScanRoute,
              ...RunQuickScanRoute,
              ...ResultRoute,
              ...StatusRoute,
              ...UserRoute
            ]
              .map((value, index) => React.cloneElement(value, { key: `protected_route_${index}` }))
          }
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<SimpleLayout />} >
          <Route path={`/result/ResultData`} element={<ResultData />} />,
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
