import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';

import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import secureApiFetch from '../../services/secureFetchApi';
import DetailsScan from './DetailsScan';
import Folder from '../../ui/Folder';
import toast from 'react-hot-toast';
import ScanData from './ScanData';

const ScanDetails = () => {
    const [scheduleDetals, setScheduleDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => setValue(newValue);
    const { id } = useParams()

    const getDetails = () => {
        setLoading(true);
        secureApiFetch(`/api/v1/schedule_scan/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setScheduleDetails(data)
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Failed to retrieve data for this ID.');
                console.error('Client ID Not Found:', error);
            });
    }
    useEffect(() => {
        getDetails()
    }, [])
    return (
        <div className='bg-white  shadow rounded'>
            <div className='flex items-center justify-between py-1 px-3'>
                <div className=''>
                    <NavigateBreadCrumbs>
                        <Link to="/">Back</Link>
                        <Link to="/schedulescan">Schedule Scan</Link>
                        <span>Details</span>
                    </NavigateBreadCrumbs>
                    <Folder label='Schedule Scan Details' icon={<TextSnippetIcon color='3A3D89' />} />
                </div>
                <Link to={`/schedulescan/edit/${id}`}>
                    <Button variant='contained' color='customColor' >
                        Edit
                    </Button>
                </Link>
            </div>

            <div className='min-h-[80vh] -mt-3'>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Details Scan" value="1" />
                                <Tab label="Scan Code Table" value="2" />
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ padding: 1 }}>
                            <ScanData scheduleDetals={scheduleDetals} />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: 2 }}>
                            <DetailsScan scheduleDetals={scheduleDetals} loading={loading} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>

        </div>
    )
}

export default ScanDetails; 