import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { toast } from 'react-hot-toast';

import EditSharpIcon from '@mui/icons-material/EditSharp';

import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import secureApiFetch from '../../services/secureFetchApi'
import Folder from '../../ui/Folder';
import FormService from './form'

const EditService = () => {
    const [editSercice, setEditService] = useState()
    const navigate = useNavigate()

    const { id } = useParams()
    const getData = () => {
        secureApiFetch(`/api/v1/service/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setEditService(data)
            })

    }

    const handleEditSubmit = (formData) => {
        secureApiFetch(`/api/v1/service/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((res) => {
                if (res.ok) {
                    toast.success('Service Data updated successfully');
                    setTimeout(() => {
                        navigate('/services')
                    }, 2000);
                } else {
                    throw new Error('Failed to update service data');
                }
            })
            .catch((error) => {
                toast.error('Failed to update service data');
                console.error('Update service error:', error);
            });
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='bg-white shadow rounded '>
            <div className='py-1 px-3'>
                <NavigateBreadCrumbs>
                    <span>Back</span>
                    <Link to="/services">Services</Link>
                    <span>Edit Services</span>
                </NavigateBreadCrumbs>
                <Folder label='Edit Services' icon={<EditSharpIcon color='3A3D89' />} />
            </div>
            <hr className='mb-4' />

            <div className='min-h-[73vh]'>
                <FormService isEdit={true} editService={editSercice} onSubmit={handleEditSubmit} />
            </div>
        </div>
    )
}

export default EditService;