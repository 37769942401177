import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import PersonIcon from '@mui/icons-material/Person';
import Folder from '../../ui/Folder';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import UserTable from './table';
import secureApiFetch from '../../services/secureFetchApi';
import toast from 'react-hot-toast';

const User = () => {
    const [usersData, setUsersData] = useState([])
    const [loading, setLoading] = useState(false)

    const getUsers = () => {
        setLoading(true);
        secureApiFetch(`/api/v1/user?page=1`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setUsersData(data);
                setLoading(false);
            })
            .catch((error) => {
                toast.error('Failed to retrieve data for this ID.');
                console.error('Client ID Not Found:', error);
            });
    }

    useEffect(()=>{
        getUsers()
    },[])

    return (
        <div className='bg-white shadow rounded'>
            <div className='flex justify-between items-center py-1 px-3'>
                <div className=''>
                    <NavigateBreadCrumbs>
                        <span>Back</span>
                        <span>Users</span>
                    </NavigateBreadCrumbs>
                    <Folder label='Users' icon={<PersonIcon color='#3D3A89' />} />
                </div>
                <Link to='/create'>
                    <Button variant="contained" color='customColor' endIcon={<AddIcon />}>
                        Create User
                    </Button>
                </Link>
            </div>
            <hr />
            <div className='min-h-[75vh] px-4'>
                <UserTable usersData={usersData} loading={loading} getUsers={getUsers} />
            </div>
        </div>
    )
}

export default User