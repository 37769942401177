import React from 'react'
import { toast } from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import EditNoteSharpIcon from '@mui/icons-material/EditNoteSharp';
import NavigateBreadCrumbs from '../../ui/NavigateBreadCrumbs';
import secureApiFetch from '../../services/secureFetchApi';
import Folder from '../../ui/Folder';
import FormService from './form';

const CreateService = () => {
    const navigate = useNavigate()

    const handleSubmit = (formData) => {
        secureApiFetch(`/api/v1/service`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then((res) => {
                if (res.ok) {
                    toast.success('Created Service successfully');
                    setTimeout(() => {
                        navigate('/services')
                    }, 2000);
                } else {
                    throw new Error('Failed to create service');
                }
            })
            .catch((error) => {
                toast.error('Failed to create service');
                console.error('create service error:', error);
            });
    }
    return (
        <div className='bg-white shadow rounded '>
            <div className='py-1 px-3'>
                <NavigateBreadCrumbs>
                    <span>Back</span>
                    <Link to="/services">Services</Link>
                    <span>Create Services</span>
                </NavigateBreadCrumbs>
                <Folder label='Create Services' icon={<EditNoteSharpIcon color='3A3D89' />} />
            </div>
            <hr className='my-2 ' />
            <div className='min-h-[85vh]'>
                <FormService onSubmit={handleSubmit} />
            </div>
        </div>
    )
}

export default CreateService